import React, {useEffect, useState} from "react";
import Confetti from 'react-confetti'

export const ConfettiComponent = (props: { startConfetti: boolean, run: boolean }) => {
    const size:any = useWindowSize();
    let confetti = (<div></div>);
    if (props.startConfetti) {
        confetti = (<Confetti recycle={props.run}
                              width={size.width}
                              height={size.height}
                              confettiSource={{
                                  w: 10,
                                  h: 10,
                                  x: size.width / 2,
                                  y: size.height < 600 ? size.height / 2 : size.height * 0.85 / 2,
                              }}
        />);
    }
    return confetti;
};

const tweenFunction = (currentTime: number, currentValue: number, targetValue: number, duration: number, s?: number) => {

};


// Hook
const useWindowSize = () => {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect((): any => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}