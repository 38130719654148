import React, {useState} from 'react';
import {ConfettiComponent} from './Confetti/confetti'
import {ClapButton} from './Confetti/Clap/clap-button';
import './App.css';

function App() {
    const [confettiStatus, setConfettiStatus] = useState(
        {
            startConfetti: false,
            disabled: false,
            run: false
        }
    );
    const clapHandler = (event?: React.FormEvent<HTMLInputElement>) => {
        console.log(event);

        if (event) {
            setConfettiStatus({startConfetti: true, disabled: true, run: true});

            setTimeout(()=> {
                setConfettiStatus({startConfetti: true, disabled: true, run: false});
            }, 1000);
        }
    };


    return (
        <div className="App">
            <header className="App-header">
                <p>
                    <ClapButton clap={clapHandler} disabled={confettiStatus.disabled}/>
                </p>
                <p className="clap-welcome">
                    Welcome to Clap!
                </p>
            </header>
            <ConfettiComponent startConfetti={confettiStatus.startConfetti} run={confettiStatus.run}/>
            <footer>
                <span>Copyright &copy; <a
                    className="App-link"
                    href="https://engcode.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    engcode
        </a> <span>& </span>
                <a
                    className="App-link"
                    href="https://oznovi.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                     oznovi
        </a></span>
            </footer>

        </div>
    );
}


export default App;
